<template>
  <div class="header__sub-menu">
    <HeaderSubmenuColumn
      v-for="(items, index) in menuItems.secondLevel"
      :key="`header-sub-menu-${index}`"
      :menu-items="items"
      :menu-index="index"
      @handleLink="(link) => $emit('handleLink', link)"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import HeaderSubmenuColumn from './HeaderSubmenuColumn.vue';

export default defineComponent({
  name: 'HeaderSubmenu',
  components: {
    HeaderSubmenuColumn,
  },
  props: {
    menuItems: {
      type: Object,
      required: true,
    },
    menuIndex: {
      type: Number,
      required: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.header__sub-menu {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  z-index: 6;
  margin: 0 auto;
  background-color: white;
  padding: 1rem;
  border-bottom: 1px solid #d5d5d5;
}
@include from-tablet-min {
  .header__sub-menu {
    position: absolute;
    width: 100vw;
    overflow-y: scroll;
    left: 0;
    border-top: 1px solid #d5d5d5;
    height: 30.6vh;
    padding: 4.8vh 14.7% 0 14.7%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-row-gap: 0;
    grid-column-gap: 0;
    top: 100%;
  }
}
</style>
