<template>
  <div class="whatsapp-chat">
    <a target="_blank" href="https://wa.me/+393356917009">
      <nuxt-img
        class="whatsapp-chat__img"
        alt="WhatsApp Icon"
        src="/icons/WhatsAppIcon.png"
      />
    </a>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'WhatsAppChatButton',
});
</script>

<style lang="scss">
.whatsapp-chat {
  position: fixed;
  bottom: 1.25rem;
  right: 1.25rem;
  &__img {
    width: 4.25rem;
    height: 4.25rem;
  }
}
</style>
