<template>
  <div class="text-with-discount-box" v-show="label.includes('|||')">
    <span class="text-with-discount-box__label">
      {{ label.split('|||')[0] }}
    </span>
    <span class="text-with-discount-box__sticker">
      {{ label.split('|||')[1] }}
    </span>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'TextWithDiscountBox',
  components: {},
  props: {
    label: {
      type: String,
      required: true,
      validator(value) {
        return value.includes('|||');
      },
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.text-with-discount-box {
  display: flex;
  gap: 1rem;
  align-items: center;
  justify-content: flex-start;
  &__label {
    font-family: var(--font-family-albert-sans);
    font-style: var(--font-style-normal);
    font-weight: 600;
    line-height: inherit;
    text-transform: uppercase;
  }
  &__sticker {
    background-color: #bb2649;
    color: var(--c-white);
    padding: 0.25rem;
  }
}
</style>
