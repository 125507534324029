import HeaderHamburger from './HeaderHamburger.vue';
import HeaderLogo from './HeaderLogo.vue';
import HeaderSearch from './HeaderSearch.vue';
import HeaderCountry from './HeaderCountry.vue';
import HeaderMenu from './HeaderMenu.vue';
import HeaderWishlistCartUser from './HeaderWishlistCartUser.vue';
import HeaderTopBar from './HeaderTopBar.vue';

export { default as HeaderHamburger } from './HeaderHamburger.vue';
export { default as HeaderLogo } from './HeaderLogo.vue';
export { default as HeaderSearch } from './HeaderSearch.vue';
export { default as HeaderCountry } from './HeaderCountry.vue';
export { default as HeaderMenu } from './HeaderMenu.vue';
export { default as HeaderWishlistCartUser } from './HeaderWishlistCartUser.vue';
export { default as HeaderTopBar } from './HeaderTopBar.vue';

export const HeaderComponents = {
  HeaderHamburger,
  HeaderLogo,
  HeaderSearch,
  HeaderCountry,
  HeaderMenu,
  HeaderWishlistCartUser,
  HeaderTopBar,
};
