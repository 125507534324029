<template>
  <div
    v-if="$device.isDesktop"
    class="header__menu"
    :class="[
      mobileShowMenu && 'header__menu--visible',
      mobileShowMenu && !hasSubMenu && 'header__menu--main-only',
    ]"
  >
    <HeaderHtmlMenuForCrawlers v-if="menuItems" :menu-items="menuItems" />
    <div
      v-for="({ link, label }, menuIndex) in menuItems"
      :key="`header-menu-first-level-${menuIndex}`"
      class="header__menu__first-level__item"
      @mouseover="showSubmenu(menuIndex)"
      @mouseleave="hideSubmenu"
    >
      <div
        @click="handleLink(localePath(link))"
        class="header__menu__first-level__item__title"
      >
        {{ label }}
      </div>
      <nuxt-img
        v-visible="
          menuItems && menuItems[menuIndex] && menuItems[menuIndex].secondLevel
        "
        class="header__menu__first-level__item__arrow"
        :class="
          subMenu === menuIndex &&
          'header__menu__first-level__item__arrow--active'
        "
        src="/header/right-arrow.svg"
        @click.native="
          subMenu !== null && subMenu !== undefined && subMenu === menuIndex
            ? hideSubmenu()
            : showSubmenu(menuIndex)
        "
      />
    </div>
    <HeaderSubmenu
      v-if="hasSubMenu"
      :menu-items="menuItems[subMenu]"
      :menu-index="subMenu"
      @mouseover.native="showSubmenu(subMenu)"
      @mouseleave.native="hideSubmenu"
      @handleLink="(link) => handleLink(link)"
    />
  </div>
  <div
    v-else
    class="header__menu"
    :class="[
      mobileShowMenu && 'header__menu--visible',
      mobileShowMenu && !hasSubMenu && 'header__menu--main-only',
    ]"
  >
    <HeaderHtmlMenuForCrawlers v-if="menuItems" :menu-items="menuItems" />
    <div
      v-for="({ link, label }, menuIndex) in menuItems"
      :key="`header-menu-first-level-${menuIndex}`"
      class="header__menu__first-level__item"
    >
      <div
        @click="handleLink(localePath(link))"
        class="header__menu__first-level__item__title"
      >
        {{ label }}
      </div>
      <nuxt-img
        v-visible="
          menuItems && menuItems[menuIndex] && menuItems[menuIndex].secondLevel
        "
        class="header__menu__first-level__item__arrow"
        :class="
          subMenu === menuIndex &&
          'header__menu__first-level__item__arrow--active'
        "
        src="/header/right-arrow.svg"
        @click.native="
          subMenu !== null && subMenu !== undefined && subMenu === menuIndex
            ? hideSubmenu()
            : showSubmenu(menuIndex)
        "
      />
      <HeaderSubmenu
        v-if="hasSubMenu && menuIndex === subMenu"
        :menu-items="menuItems[menuIndex]"
        :menu-index="menuIndex"
        @handleLink="(link) => handleLink(link)"
      />
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  useFetch,
  ref,
  computed,
  useRouter,
} from '@nuxtjs/composition-api';
import { useMenu } from '@gemini-vsf/composables';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
import { useUiState } from '~/composables';
import HeaderHtmlMenuForCrawlers from './HeaderHtmlMenuForCrawlers.vue';
import HeaderSubmenu from './HeaderSubmenu.vue';

export default defineComponent({
  name: 'HeaderMenu',
  components: {
    HeaderHtmlMenuForCrawlers,
    HeaderSubmenu,
  },
  props: {
    mobileShowMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const { toggleMenuIsOpen } = useUiState();
    const router = useRouter();
    const { search: loadMenu, menu: menuFromApi } = useMenu('header-menu');
    const menuItems = ref();
    const subMenu = ref();
    const hasSubMenu = computed(
      () =>
        !!(
          subMenu.value !== null &&
          subMenu.value !== undefined &&
          menuItems.value &&
          menuItems.value[subMenu.value] &&
          menuItems.value[subMenu.value].secondLevel
        )
    );

    const handleLink = async (link) => {
      subMenu.value = null;
      emit('toggleMenu', false);
      await router.push(link);
    };

    const transformMenuItems = (items) =>
      items?.map((item) => {
        const transformedItem = {
          label: item.label,
          link: item.link,
        };
        if (item.children && item.children.length > 0) {
          transformedItem.secondLevel = item.children.map((child) => {
            const secondLevelItem = {
              label: child.label,
              link: child.link,
            };
            if (child.children && child.children.length > 0) {
              secondLevelItem.thirdLevel = child.children.map((grandChild) => ({
                label: grandChild.label,
                link: grandChild.link,
              }));
            }
            return secondLevelItem;
          });
        }
        return transformedItem;
      }) || [];

    const debouncedShowSubmenu = debounce((index) => {
      subMenu.value = index;
      toggleMenuIsOpen(true);
    }, 300);

    const debouncedHideSubmenu = debounce(() => {
      subMenu.value = null;
      toggleMenuIsOpen(false);
    }, 300);

    const showSubmenu = (index) => {
      debouncedHideSubmenu.cancel();
      debouncedShowSubmenu(index);
    };

    const hideSubmenu = () => {
      debouncedShowSubmenu.cancel();
      debouncedHideSubmenu();
    };

    useFetch(async () => {
      await loadMenu({ code: 'header-menu' });
      menuItems.value = transformMenuItems(menuFromApi.value?.items);
    });

    return {
      menuItems,
      showSubmenu,
      hideSubmenu,
      subMenu,
      hasSubMenu,
      handleLink,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__menu {
  display: none;
  z-index: 6;
  &--visible {
    @include to-mobile-max {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      position: absolute;
      top: 100%;
      left: 0;
      width: 100vw;
      height: calc(100vh - 8.6875rem);
      overflow-y: scroll;
      z-index: 6;
      background-color: white;
      padding: 2rem;
    }
  }
  &--main-only {
    @include to-mobile-max {
      height: calc(100vh - 8.6875rem);
    }
  }
  &__first-level {
    &__item {
      display: grid;
      grid-template-columns: 1fr 2rem;
      align-items: center;
      &__title {
        font-family: var(--font-family-albert-sans);
        font-size: var(--font-size-15);
        letter-spacing: 0.1031rem;
        line-height: 1.5625rem;
        font-weight: var(--font-weight-bold);
        text-transform: uppercase;
        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }
      &__arrow {
        &--active {
          transform: rotate(90deg);
        }
      }
      @include to-mobile-max {
        &:last-child {
          padding-bottom: 2rem;
        }
      }
    }
  }
}
@include from-tablet-min {
  .header__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    height: auto;
    padding: 0;
    gap: 2.75rem;
    &__first-level__item {
      &__arrow {
        display: none;
      }
    }
  }
}
</style>
