<template>
  <div class="header__html-menu-for-crawlers">
    <div
      v-for="(firstLevelMenu, firstLevelMenuIndex) in menuItems"
      :key="`header-menu-first-level-${firstLevelMenuIndex}`"
    >
      <a :href="localePath(firstLevelMenu.link)">
        {{ firstLevelMenu.label }}
      </a>
      <div v-if="firstLevelMenu.secondLevel">
        <div
          v-for="(
            secondLevelMenu, secondLevelMenuIndex
          ) in firstLevelMenu.secondLevel"
          :key="`header-menu-second-level-${secondLevelMenuIndex}`"
        >
          <a :href="localePath(secondLevelMenu.link)">
            {{ secondLevelMenu.label }}
          </a>
          <div v-if="secondLevelMenu.thirdLevel">
            <div
              v-for="(
                thirdLevelMenu, thirdLevelMenuIndex
              ) in secondLevelMenu.thirdLevel"
              :key="`header-menu-third-level-${thirdLevelMenuIndex}`"
            >
              <a :href="localePath(thirdLevelMenu.link)">
                {{ thirdLevelMenu.label }}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeaderHtmlMenuForCrawlers',
  components: {},
  props: {
    menuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {},
});
</script>

<style lang="scss" scoped>
.header__html-menu-for-crawlers {
  display: none;
}
</style>
