<template>
  <div class="header__hamburger" @click="toggleMenu">
    <nuxt-img
      class="header__hamburger__img"
      :src="mobileShowMenu ? '/icons/Cross.svg' : '/hamburger.svg'"
      alt="Toggle Menu"
    />
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderHamburger',
  components: {},
  props: {
    mobileShowMenu: {
      type: Boolean,
      default: false,
    },
  },
  setup(_, { emit }) {
    const toggleMenu = () => {
      emit('toggleMenu');
    };

    return {
      toggleMenu,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__hamburger {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.625rem;
  &__img {
    width: 1.75rem;
    height: 1.75rem;
  }
}
@include from-tablet-min {
  .header__hamburger {
    display: none;
  }
}
</style>
