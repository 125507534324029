<template>
  <span class="header__top-bar text-14-regular-white" v-show="!isScrolledPast">
    {{ $gt('Free shipping for orders over €55.00') }}
  </span>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  onUnmounted,
} from '@nuxtjs/composition-api';
// eslint-disable-next-line import/no-extraneous-dependencies
import debounce from 'lodash.debounce';
import { useTranslation } from '~/composables';

export default defineComponent({
  name: 'HeaderTopBar',
  setup() {
    const { $gt } = useTranslation('translations');
    const isScrolledPast = ref(false);

    const checkScroll = () => {
      try {
        const topBarElement = document.querySelector('.header__top-bar');
        isScrolledPast.value = window.scrollY > topBarElement.offsetHeight;
      } catch (error) {
        console.warn(error);
      }
    };

    const debouncedCheckScroll = debounce(checkScroll, 200);

    onMounted(() => {
      if (typeof window !== 'undefined') {
        window.addEventListener('scroll', debouncedCheckScroll);
      }
    });

    onUnmounted(() => {
      if (typeof window !== 'undefined') {
        window.removeEventListener('scroll', debouncedCheckScroll);
      }
      debouncedCheckScroll.cancel(); // Cancel any pending execution
    });

    return { isScrolledPast, $gt };
  },
});
</script>

<style lang="scss" scoped>
.header__top-bar {
  height: 2.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--c-black);
  color: var(--c-white);
  font-size: 0.8125rem;
  line-height: 25px;
  letter-spacing: 0.0081rem;
}
</style>
