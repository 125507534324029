<template>
  <nuxt-link :to="localePath('/')" class="header__logo">
    <nuxt-img class="header__logo__img" src="/logo.jpg" alt="Tre Ponti logo" />
  </nuxt-link>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
// import {} from '@storefront-ui/vue';

export default defineComponent({
  name: 'HeaderLogo',
  components: {},
  props: {},
  setup() {},
});
</script>

<style lang="scss">
.header__logo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 0.625rem;
  z-index: 6;
  &__img {
    height: 4.05rem;
    width: 4.05rem;
  }
  @include pointer;
}
@include from-tablet-min {
  .header__logo {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0.5031rem;
    padding-bottom: 0.75rem;
    padding-left: 2.6vw;
    &__img {
      height: 6.7062rem;
      width: 6.7062rem;
    }
  }
}
</style>
