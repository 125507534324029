<template>
  <div class="header__country">
    <HeaderLanguageSwitcher />
    <div
      @click="handleClickChangeCountry"
      class="header__country__location__img__container"
    >
      <nuxt-img
        class="header__country__location__img"
        src="/location.svg"
        alt="Country Location"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '~/composables';
import HeaderLanguageSwitcher from './HeaderLanguageSwitcher.vue';

export default defineComponent({
  name: 'HeaderCountry',
  components: { HeaderLanguageSwitcher },
  props: {},
  setup() {
    const { toggleShowChangeCountry } = useUiState();

    const handleClickChangeCountry = () => {
      toggleShowChangeCountry();
    };

    return {
      handleClickChangeCountry,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__country {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25%;
  &__location__img {
    width: 1.125rem;
    height: 1.25rem;
    @include pointer;
    &__container {
      z-index: 1;
    }
  }
  margin: 0 auto;
}
@include from-tablet-min {
  .header__country {
    margin: 0;
    gap: 12.6%;
    justify-content: flex-end;
    padding-right: 12%;
  }
}
</style>
