<template>
  <div class="header__sub-menu__column">
    <div
      class="header__sub-menu__column__main-link__container"
      v-if="menuItems.link"
    >
      <div
        class="header__sub-menu__column__main-link"
        @click="$emit('handleLink', localePath(menuItems.link))"
      >
        <TextWithDiscountBox
          v-if="menuItems.label.includes('|||')"
          :label="menuItems.label"
        />
        <span v-else>{{ menuItems.label }}</span>
      </div>
    </div>
    <div
      class="header__sub-menu__column__sub-link__container"
      v-if="menuItems.thirdLevel"
    >
      <div
        class="header__sub-menu__column__sub-link"
        v-for="(
          { link: thirdLevelLink, label: thirdLevelLabel }, thirdLevelIndex
        ) in menuItems.thirdLevel"
        :key="`header__sub-menu__column-${menuIndex}-${thirdLevelIndex}`"
        @click="$emit('handleLink', localePath(thirdLevelLink))"
      >
        <div
          class="header__sub-menu__column__sub-link--with-label"
          v-if="thirdLevelLabel.includes('|||')"
        >
          <span class="header__sub-menu__column__sub-link__label">
            {{ thirdLevelLabel.split('|||')[0] }}
          </span>
          <span class="header__sub-menu__column__sub-link--with-label__sticker">
            {{ thirdLevelLabel.split('|||')[1] }}
          </span>
        </div>
        <div class="header__sub-menu__column__sub-link__label" v-else>
          {{ thirdLevelLabel }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import TextWithDiscountBox from '../General/TextWithDiscountBox.vue';

export default defineComponent({
  name: 'HeaderSubmenuColumn',
  components: {
    TextWithDiscountBox,
  },
  props: {
    menuItems: {
      type: Object,
      required: true,
    },
    menuIndex: {
      type: Number,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.header__sub-menu__column {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
  &__main-link,
  &__sub-link {
    font-family: var(--font-family-albert-sans);
    font-size: 0.8125rem;
    line-height: 1.5625rem;
    letter-spacing: 0.0894rem;
    color: var(--c-black);
    text-transform: uppercase;
  }
  &__main-link {
    font-weight: bold;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  &__sub-link {
    &__container {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
    &__label {
      font-family: var(--font-family-albert-sans);
      font-weight: 400;
      text-transform: uppercase;
      @include text-15-light-light_black;
      &:hover {
        @include text-15-light-light_black-bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &--with-label {
      display: flex;
      gap: 1rem;
      align-items: center;
      justify-content: flex-start;
      &__sticker {
        background-color: #bb2649;
        color: var(--c-white);
        padding: 0.25rem;
      }
    }
  }
}
</style>
