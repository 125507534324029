import { render, staticRenderFns } from "./HeaderLanguageSwitcher.vue?vue&type=template&id=722f861d&scoped=true&"
import script from "./HeaderLanguageSwitcher.vue?vue&type=script&lang=js&"
export * from "./HeaderLanguageSwitcher.vue?vue&type=script&lang=js&"
import style0 from "./HeaderLanguageSwitcher.vue?vue&type=style&index=0&id=722f861d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "722f861d",
  null
  
)

export default component.exports