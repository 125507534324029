var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.$device.isDesktop)?_c('div',{staticClass:"header__menu",class:[
    _vm.mobileShowMenu && 'header__menu--visible',
    _vm.mobileShowMenu && !_vm.hasSubMenu && 'header__menu--main-only' ]},[(_vm.menuItems)?_c('HeaderHtmlMenuForCrawlers',{attrs:{"menu-items":_vm.menuItems}}):_vm._e(),_vm._v(" "),_vm._l((_vm.menuItems),function(ref,menuIndex){
  var link = ref.link;
  var label = ref.label;
return _c('div',{key:("header-menu-first-level-" + menuIndex),staticClass:"header__menu__first-level__item",on:{"mouseover":function($event){return _vm.showSubmenu(menuIndex)},"mouseleave":_vm.hideSubmenu}},[_c('div',{staticClass:"header__menu__first-level__item__title",on:{"click":function($event){_vm.handleLink(_vm.localePath(link))}}},[_vm._v("\n      "+_vm._s(label)+"\n    ")]),_vm._v(" "),_c('nuxt-img',{directives:[{name:"visible",rawName:"v-visible",value:(
        _vm.menuItems && _vm.menuItems[menuIndex] && _vm.menuItems[menuIndex].secondLevel
      ),expression:"\n        menuItems && menuItems[menuIndex] && menuItems[menuIndex].secondLevel\n      "}],staticClass:"header__menu__first-level__item__arrow",class:_vm.subMenu === menuIndex &&
        'header__menu__first-level__item__arrow--active',attrs:{"src":"/header/right-arrow.svg"},nativeOn:{"click":function($event){_vm.subMenu !== null && _vm.subMenu !== undefined && _vm.subMenu === menuIndex
          ? _vm.hideSubmenu()
          : _vm.showSubmenu(menuIndex)}}})],1)}),_vm._v(" "),(_vm.hasSubMenu)?_c('HeaderSubmenu',{attrs:{"menu-items":_vm.menuItems[_vm.subMenu],"menu-index":_vm.subMenu},on:{"handleLink":function (link) { return _vm.handleLink(link); }},nativeOn:{"mouseover":function($event){return _vm.showSubmenu(_vm.subMenu)},"mouseleave":function($event){return _vm.hideSubmenu.apply(null, arguments)}}}):_vm._e()],2):_c('div',{staticClass:"header__menu",class:[
    _vm.mobileShowMenu && 'header__menu--visible',
    _vm.mobileShowMenu && !_vm.hasSubMenu && 'header__menu--main-only' ]},[(_vm.menuItems)?_c('HeaderHtmlMenuForCrawlers',{attrs:{"menu-items":_vm.menuItems}}):_vm._e(),_vm._v(" "),_vm._l((_vm.menuItems),function(ref,menuIndex){
  var link = ref.link;
  var label = ref.label;
return _c('div',{key:("header-menu-first-level-" + menuIndex),staticClass:"header__menu__first-level__item"},[_c('div',{staticClass:"header__menu__first-level__item__title",on:{"click":function($event){_vm.handleLink(_vm.localePath(link))}}},[_vm._v("\n      "+_vm._s(label)+"\n    ")]),_vm._v(" "),_c('nuxt-img',{directives:[{name:"visible",rawName:"v-visible",value:(
        _vm.menuItems && _vm.menuItems[menuIndex] && _vm.menuItems[menuIndex].secondLevel
      ),expression:"\n        menuItems && menuItems[menuIndex] && menuItems[menuIndex].secondLevel\n      "}],staticClass:"header__menu__first-level__item__arrow",class:_vm.subMenu === menuIndex &&
        'header__menu__first-level__item__arrow--active',attrs:{"src":"/header/right-arrow.svg"},nativeOn:{"click":function($event){_vm.subMenu !== null && _vm.subMenu !== undefined && _vm.subMenu === menuIndex
          ? _vm.hideSubmenu()
          : _vm.showSubmenu(menuIndex)}}}),_vm._v(" "),(_vm.hasSubMenu && menuIndex === _vm.subMenu)?_c('HeaderSubmenu',{attrs:{"menu-items":_vm.menuItems[menuIndex],"menu-index":menuIndex},on:{"handleLink":function (link) { return _vm.handleLink(link); }}}):_vm._e()],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }