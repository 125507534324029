<template>
  <div class="header__country__language" @click="toggleDropdown">
    <div class="text-15-light-black header__country__language__text">
      {{ mappedLanguage }}
    </div>
    <nuxt-img
      class="header__country__language__img"
      src="/small_arrow_down.svg"
      alt="Country Language"
    />
    <div class="dropdown" :class="!showDropdown && 'dropdown__hidden'">
      <a class="dropdown-item" :href="`/${changeLanguageUrl}`">
        {{ LANGUAGES_MAP[changeLanguageUrl] }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  useRoute,
  computed,
} from '@nuxtjs/composition-api';

export default defineComponent({
  name: 'HeaderLanguageSwitcher',
  components: {},
  props: {},
  setup() {
    const LANGUAGES_MAP = {
      it: 'ITA',
      en: 'ENG',
    };

    const {
      value: { path },
    } = useRoute();

    const currentLanguage = ref(path?.split('/')?.[1]);
    const mappedLanguage = computed(() => LANGUAGES_MAP[currentLanguage.value]);
    const changeLanguageUrl = computed(() =>
      currentLanguage.value === 'it' ? 'en' : 'it'
    );

    const showDropdown = ref(false);
    const languages = ['ITA', 'ENG'];

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const handleClickOutside = (e) => {
      if (showDropdown.value) {
        showDropdown.value = false;
      }
    };

    const changeLanguage = () => {
      showDropdown.value = false;
    };

    return {
      showDropdown,
      languages,
      toggleDropdown,
      changeLanguage,
      mappedLanguage,
      currentLanguage,
      changeLanguageUrl,
      LANGUAGES_MAP,
      handleClickOutside,
    };
  },
});
</script>

<style lang="scss" scoped>
.header__country__language {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.25rem;
  @include pointer;

  .dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    z-index: 7;
    &__hidden {
      display: none;
    }
  }

  .dropdown-item {
    padding: 0.5rem;
    cursor: pointer;

    &:hover {
      background-color: #eee;
    }
  }
  &__text {
    font-weight: var(--font-weight-600);
    font-family: var(--font-family-albert-sans);
  }
}
</style>
